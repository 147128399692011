.react-datepicker {
  font-family: Avenir, system-ui, Helvetica, Arial, sans-serif;
}

.react-datepicker__triangle {
  border-bottom-color: #fff !important;
}

.react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}

.react-datepicker__current-month {
  font-size: 1rem;
  font-weight: normal;
}

.react-datepicker__navigation {
  border: none;
  top: 8px;
  width: 1.5rem;
  height: 1.5rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.react-datepicker__navigation--previous {
  /* box-icons-chevron-left */
  /* background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill:rgba(0, 0, 0, 1);transform:;-ms-filter:"><path d="M13.939 4.939L6.879 12 13.939 19.061 16.061 16.939 11.121 12 16.061 7.061z"></path></svg>'); */
}

.react-datepicker__navigation--next {
  /* box-icons-chevron-right */
  /* background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill:rgba(0, 0, 0, 1);transform:;-ms-filter:"><path d="M10.061 19.061L17.121 12 10.061 4.939 7.939 7.061 12.879 12 7.939 16.939z"></path></svg>'); */
}

.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before {
  border-color: #000;
}

.react-datepicker__day-name {
  width: 2rem;
  margin: 0.25rem;
  margin-bottom: 0;
}

.react-datepicker__month {
  margin: 0.25rem;
  margin-top: 0;
}

.react-datepicker__day {
  background-image: url('./images/date-enabled.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  color: #54b948;
  border: none;
  width: 2rem;
  height: 2rem;
  margin: 0.25rem;
  line-height: 2rem;
  font-weight: 700;
}
.react-datepicker__day:hover {
  background-color: transparent;
  text-decoration: underline;
}

.react-datepicker__day--selected {
  background-image: url('./images/date-selected.svg');
  color: #fff;
}

.react-datepicker__day--disabled {
  background-image: url('./images/date-disabled.svg');
  color: #9b9b9b;
  cursor: default !important;
}
.react-datepicker__day--disabled:hover {
  text-decoration: none;
}

.react-datepicker__day--disabled.react-datepicker__day--selected {
  background-image: url('./images/date-disabled-selected.svg');
}

.react-datepicker__day--keyboard-selected {
  border: none;
  border-radius: 100%;
}
.react-datepicker__day--keyboard-selected:hover {
  background-color: transparent;
}

.react-datepicker__day:focus {
  outline-style: dotted;
}
